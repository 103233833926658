import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaPreviewModalComponent } from './models/media-preview-modal/media-preview-modal.component';
import { ClickOutsideDirective } from './models/media-preview-modal/click-outside.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClickOutsideDirective  // Export the component to use in other modules
  ]
})
export class ClickOutside { }
